import { Showcase } from "./Showcase";
import { Slides } from "./Slides";
import { Cursor } from "./Cursor";
import image1 from "../images/interior.jpg";
import image2 from "../images/food.jpg";
import image3 from "../images/product.jpg";
import image4 from "../images/nature.jpg";
import image5 from "../images/people.jpg";
import image6 from "../images/wedding.jpg";

const container = document.getElementById("app");
const cursor = new Cursor(document.querySelector(".cursor"));
const slidesData = [
  {
    image: image1,
    title: "Spaces",
    meta: "",
    moreLink: "Read"
  },
  {
    image: image2,
    title: "Food & Drink",
    meta: "",
    moreLink: "Read"
  },
  {
    image: image3,
    title: "Products",
    meta: " ",
    moreLink: "Read"
  },
  {
    image: image4,
    title: "Nature & Travel",
    meta: "",
    moreLink: "Read"
  },
  {
    image: image5,
    title: "People",
    meta: "",
    moreLink: "Read"
  },
  {
    image: image6,
    title: "Wedding",
    meta: "",
    moreLink: "Read"
  }
];

const slides = new Slides(slidesData);
const showcase = new Showcase(slidesData, {
  onActiveIndexChange: activeIndex => {
    slides.onActiveIndexChange(activeIndex);
  },
  onIndexChange: index => {
    slides.onMove(index);
  },
  onZoomOutStart: ({ activeIndex }) => {
    cursor.enter();
    slides.appear();
  },
  onZoomOutFinish: ({ activeIndex }) => {},
  onFullscreenStart: ({ activeIndex }) => {
    cursor.leave();
    slides.disperse(activeIndex);
  },
  onFullscreenFinish: ({ activeIndex }) => {}
});

showcase.mount(container);
slides.mount(container);
showcase.render();

window.addEventListener("resize", function() {
  showcase.onResize();
});

window.addEventListener("mousemove", function(ev) {
  showcase.onMouseMove(ev);
});
